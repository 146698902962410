const customFiles = document.querySelectorAll('.promo-landing-page .Ezform-field .custom-file');

const init = () => {
  if (!customFiles) return

  customFiles.forEach((el) => {
    const fileInputParents = el.closest('.Ezform-field')
    const fileInput = fileInputParents.querySelector('input[type="file"]')
    let fileHelp = fileInputParents.querySelector('.help-text')
    if (!fileHelp) {
      const newFileHelp = document.createElement('p')
      newFileHelp.classList.add('help-text')
      newFileHelp.textContent = ''
      fileInputParents.insertBefore(
        newFileHelp,
        fileInput.closest('.custom-file')
      )
      fileHelp = newFileHelp
    }
    const fileHelpContent = fileHelp.textContent

    fileInputParents.classList.add('Ezform-field--custom-file')

    fileInput.addEventListener('change', (e) => {
      const file = e.target.files[0];

      if (file) {
        if (file.size === 0) {
          fileHelp.textContent = 'Le fichier sélectionné est vide (0 octet). Veuillez choisir un fichier valide.';
          fileInput.value = '';
        } else {
          fileHelp.textContent = file.name;
        }
      } else {
        fileHelp.textContent = fileHelpContent;
      }
    });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  init()
})
