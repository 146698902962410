import Popup from '../popup/popup'

class PopupDelete extends Popup{

    init(){
        super.init()
        this.openPopupDelete()
    }

    openPopupDelete() {

    }

}

export default PopupDelete

let projectDim = document.querySelector('.BlockProjectDim');
let projectChauffEau = document.querySelector('.BlockProjectChauffe-eau');

if(projectDim) {
    document.querySelectorAll('.BlockProjectDim').forEach((item) => {
        item.querySelector('.PopupDelete .PopupDelete--actions #delete-confirm').addEventListener('click', function() {
            window.scrollTo(0, 0);
            item.closest('.BlockProjectDim').querySelector('.PopupDelete').style.display = 'none';  
            item.nextElementSibling.style.display = 'flex';
        })
    })
}

if(projectChauffEau) {
    document.querySelectorAll('.BlockProjectChauffe-eau').forEach((item) => {
        item.querySelector('.PopupDelete .PopupDelete--actions #delete-confirm').addEventListener('click', function() {
            window.scrollTo(0, 0);
            item.closest('.BlockProjectChauffe-eau').querySelector('.PopupDelete').style.display = 'none';
            if (item.nextElementSibling) item.nextElementSibling.style.display = 'flex';
        })
    })
}