import { Formio } from 'formiojs';
import utilities, {getCookie, setCookie} from "../../../scripts/utilities";

let apiUrl = window.location.protocol + "//" + window.location.host;

function converToString(data) {
    Object.keys(data).forEach(k => {
   
    if (k !== 'submit') {
      data[k] = '' + data[k];
    }        
  });
  
   return data;
}

if (getCookie("reference_chauffe_eau") !== undefined && getCookie("reference_chauffe_eau") !== null) {
    var reference_chauffe_eau_cookie = JSON.parse(getCookie("reference_chauffe_eau"));
}
if(document.getElementById('reference-thermor-chauffe-eau') && document.getElementById('reference-thermor-chauffe-eau').length > 0) {
    Formio.createForm(document.getElementById('reference-thermor-chauffe-eau'), {
        components: [
            {
                "input": false,
                "html": "<span>Vous connaissez la référence de votre chauffe-eau actuel</span>",
                "type": "content",
                "key": "content",
                "customClass": "red-block--title",
            },
            {
                components: [{
                    "columns": [
                        {
                            components: [
                                {
                                    tabindex: "1",
                                    type: 'textfield',
                                    key: 'nom',
                                    label: 'Nom du projet',
                                    input: true,
                                    defaultValue: reference_chauffe_eau_cookie?.nom || "",
                                    validate: {
                                        required: true,
                                        maxLength: '30'
                                    }
                                }
                            ],
                            "width": 6,
                            "offset": 0,
                            "push": 0,
                            "pull": 0,
                            "size": "md",
                            "currentWidth": 6
                        },
                        {
                            components: [
                                {
                                    tabindex: "2",
                                    type: "number",
                                    label: "Référence du chauffe-eau Thermor actuel",
                                    key: "reference",
                                    placeholder: "Ref.XXXXXX",
                                    input: true,
                                    inputType: 'text',
                                    defaultValue: reference_chauffe_eau_cookie?.reference || "",
                                    validate: {
                                        required: true,
                                        max: 999999,
                                        min: -999999
                                    }
                                }
                            ],
                            "width": 6,
                            "offset": 0,
                            "push": 0,
                            "pull": 0,
                            "size": "md",
                            "currentWidth": 6
                        }
                    ],
                    "type": "columns",
                    "conditional": {
                        "show": "",
                        "when": null,
                        "eq": ""
                    },
                    "key": "columns",
                }],
                "customClass": "red-block"
            },
            {
                type: 'button',
                action: 'submit',
                label: 'TROUVER',
                theme: 'primary'
            }
        ]
    }, {
        language: 'fr',
        i18n: {
            'fr': {
                required: 'Ce champ est obligatoire',
                maxLength: 'Ne peut pas être supérieur à {{length}} caractère.',
                max: 'Ne peut pas être supérieur à 6 caractères.',
                min: 'Ne peut pas être supérieur à 6 caractères.',
                'No results found': 'Aucun résultat pour cette recherche',
                'Type to search': 'Écrivez pour lancer une recherche'
            }
        }
    }).then(function (form) {
        form.options.noAlerts = true;
        form.on('submit', function (submission) {
            let idDim = 0;
            let canBeSaved = true;
            let jsonSubmissionData = converToString(submission.data);

            localStorage.removeItem("SaveDim");

            if (getCookie("reference_chauffe_eau") !== null) {
                var oldCookie = utilities.getCookie("reference_chauffe_eau");
                let jsonOldCookie = JSON.parse(oldCookie);
                idDim = jsonOldCookie['idDim'];
                delete jsonOldCookie['canBeSaved'];
                delete jsonOldCookie['idDim'];
                if (_.isEqual(jsonOldCookie, jsonSubmissionData)) {
                    canBeSaved = false;
                }
            }

            jsonSubmissionData['canBeSaved'] = canBeSaved;
            jsonSubmissionData['idDim'] = idDim;
            let objectString = JSON.stringify(jsonSubmissionData);

            document.cookie = "reference_chauffe_eau=" + objectString + "; expires=" + (new Date(Date.now() + 3600 * 1000)).toUTCString() + "; path=/";
            let idDimUrl = idDim !== 0 ? '&id=' + idDim : '';
            window.location.href = apiUrl + "/simulhome/chauffe-eau/nouvelle-reference" + "?action=" + encodeURIComponent('trouver-nouvelle-reference') + idDimUrl;
        });
    });
}